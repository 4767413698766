
const Closet=[
    {
        id:1,
        URL:require('../assets/roomCloset.jpg'),
    },
    {
        id:2,
        URL:require('../assets/roomCloset2.jpg'),
    },
    {
        id:3,
        URL:require('../assets/roomCloset3.jpg'),
    },
    {
        id:4,
        URL:require('../assets/roomCloset4.jpg'),
    },
    {
        id:5,
        URL:require('../assets/roomCloset5.jpg'),
    },
    {
        id:6,
        URL:require('../assets/roomCloset6.jpg'),
    },


]


export default Closet;