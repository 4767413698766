import React from 'react';
import Item from '../res/item';

const RoomSleep = () => {
    document.title = "עץ התבור"

    function RoomCardsDisplayed(props) {
     console.log(props)
     return ((
    <div className='rooms'>
    <div className='product-room' key={props.content.id}>
    <img className='img-BadRoom' src={props.content.URL}></img>
    </div>
    </div>
    ));
    }
    return (
    <div className='products-container-room'>
    <h1 className='room-logos'>חדרי שינה
    <a href='/'><button className='room-bath-a'>Home</button></a>
    </h1>
    {Item.map((id) => (
    <RoomCardsDisplayed content={id} />
    ))}
    </div>
            
    
    );
}

export default RoomSleep;
