const DataRoom=[
    {
        id:1,
        URL:require('../assets/bathRoom.jpg'),
    },
    {
        id:2,
        URL:require('../assets/bathRoom2.jpg'),
    },
    {
        id:3,
        URL:require('../assets/bathRoom3.jpg'),
    },
    {
        id:4,
        URL:require('../assets/bathRoom4.jpg'),
    },
    {
        id:5,
        URL:require('../assets/bathRoom5.jpg'),
    },
    {
        id:6,
        URL:require('../assets/bathRoom6.jpg'),
    },
    {
        id:7,
        URL:require('../assets/bathRoom7.jpg'),
    },
    {
        id:8,
        URL:require('../assets/bathRoom8.jpg'),
    },
    {
        id:9,
        URL:require('../assets/bathRoom9.jpg'),
    },
    {
        id:10,
        URL:require('../assets/bathRoom10.jpg'),
    },
    {
        id:11,
        URL:require('../assets/bathRoom11.jpg'),
    },
    {
        id:12,
        URL:require('../assets/bathRoom12.jpg'),
    },

]


export default DataRoom ;