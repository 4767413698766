import React from 'react';
import RoomWoods from '../res/roomWoods';
import RoomSalons from '../res/roomSlons';

const RoomSalon = () => {
    document.title = "עץ התבור"

    function RoomCardsDisplayed(props) {
        console.log(props)
        return ((
        <div className='rooms-2'>
       <div className='product-roomCloset1' key={props.content.id}>
       <img className='img-BadRoom' src={props.content.URL}></img>
       </div>
      </div>
       ));
       }
    return (
    <div>
    <div className='products-roomSalon'>
    <h1 className='roomSalon-logo'>מזנונים
    <a href='/'><button className='room-bath-a'>Home</button></a>
    </h1>
    {RoomSalons.map((id) => (
    <RoomCardsDisplayed content={id} />
    ))}
    </div>          
    </div>
    );
}

export default RoomSalon;
