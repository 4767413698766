import React from 'react';
import { useNavigate } from 'react-router-dom';

const Project = () => {
    const navigate = useNavigate();


    const navigateTo = (screenName) => {
        navigate("/" + screenName);
    }
    return (
    <body className='yourHome'>
    <div className='project-wood'>
    <div className='header-project'>
    
    <div className='project-aodot'>
    <img className='logo-imgs' src={require("../assets/logo-web.jpg")}></img>
    <ul className='ul-Home'>
    <li><h2 onClick={() => navigateTo('about')}>{'About'}</h2></li>
    <li><h2 onClick={() => navigateTo('contact')}>{'Contact'}</h2></li>
    <li><h2 onClick={() => navigateTo('')}>{'Home'}</h2></li>
    </ul>
    </div>
  
    </div> 

    <figure class="snip1193">
	<img src='https://i.pinimg.com/564x/b5/63/62/b56362980ef3d60fc10a648eb073cdc7.jpg' alt="sample31"/>
    <figcaption>
    <a href='./kitchen'><h1>מטבחים</h1></a>
    <a href="#" class="bottom-left"><i class="ion-ios-chatboxes-outline"></i></a><a href="./contact" class="bottom-right"><i class="ion-ios-telephone-outline"></i></a>
    </figcaption>
    </figure>
    
    
    <figure class="snip1193">
	<img src='https://i.pinimg.com/564x/e0/a1/ad/e0a1ad67b3436cf6b22bff565ade32db.jpg' alt="sample31"/>
    <figcaption>
    <a href='./roomSalon'><h1>מזנונים</h1></a>
    <a href="#" class="bottom-left"><i class="ion-ios-chatboxes-outline"></i></a><a href="./contact" class="bottom-right"><i class="ion-ios-telephone-outline"></i></a>
    </figcaption>
    </figure>
    
    <figure class="snip1193">
	<img src='https://i.pinimg.com/564x/0a/9f/88/0a9f880c7f0cf6c6f826fae78608bbdd.jpg' alt="sample31"/>
    <figcaption>
    <a href='./roomSleep'><h1>חדרי שינה</h1></a>
    <a href="./contact" class="bottom-left">
    <i class="ion-ios-chatboxes-outline"></i></a>
    <a href="./contact" class="bottom-right">
    <i class="ion-ios-telephone-outline"></i></a>
    </figcaption>
    </figure>

    <figure class="snip1193">
	<img src='https://i.pinimg.com/736x/21/80/0a/21800a20f6714f8eea9840f32cd62aac.jpg' alt="sample31"/>
    <figcaption>
    <a href='./roomWood'><h1>קיר מעוצב</h1></a>
    <a href="#" class="bottom-left"><i class="ion-ios-chatboxes-outline"></i></a><a href="./contact" class="bottom-right"><i class="ion-ios-telephone-outline"></i></a>
    </figcaption>
    </figure>

    <figure class="snip1193">
	<img src='https://i.pinimg.com/564x/22/29/21/2229217308e7a8bfb9deed4b40fa019b.jpg' alt="sample31"/>
    <figcaption>
    <a href='./roomBath'><h1>ארונות אמבטיה</h1></a>
    <a href="#" class="bottom-left"><i class="ion-ios-chatboxes-outline"></i></a><a href="./contact" class="bottom-right"><i class="ion-ios-telephone-outline"></i></a>
    </figcaption>
    </figure>

    <figure class="snip1193">
	<img src='https://i.pinimg.com/564x/e0/52/8b/e0528b02b2bff69924388207eec670ce.jpg' alt="sample31"/>
    <figcaption>
    <a href='./roomCloth'><h1>חדר ארונות</h1></a>
    <a href="#" class="bottom-left"><i class="ion-ios-chatboxes-outline"></i></a><a href="./contact" class="bottom-right"><i class="ion-ios-telephone-outline"></i></a>
    </figcaption>
    </figure>

    </div>
    </body>
    );
}

export default Project;
