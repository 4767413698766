import React from 'react';
import RoomWoods from '../res/roomWoods';

const RoomWood = () => {
    document.title = "עץ התבור"

    function RoomCardsDisplayed(props) {
        console.log(props)
        return ((
       <div className='room-4'>
       <div className='product-roomCloset1' key={props.content.id}>
       <img className='img-kitchen' src={props.content.URL}></img>
       </div>
       </div>
       ));
       }
    return (
    <div>
    <div className='products-roomCloset'>
    <h1 className='room-logos'>קיר מעוצב
    <a href='/'><button className='room-bath-a'>Home</button></a>
    </h1>
    {RoomWoods.map((id) => (
    <RoomCardsDisplayed content={id} />
    ))}
    </div>        
    </div>
    );
}

export default RoomWood;
