
const Data=[
    {
        id:1,
        URL:require('../assets/kitchen-1.jpg'),
    },
    {
        id:2,
        URL:require('../assets/kitchen-2.jpg'),
    },
    {
        id:3,
        URL:require('../assets/kitchen-3.jpg'),
    },
    {
        id:4,
        URL:require('../assets/kitchen-4.jpg'),
    },
    {
        id:5,
        URL:require('../assets/kitchen-5.jpg'),
    },
    {
        id:6,
        URL:require('../assets/kitchen-6.jpg'),
    },
    {
        id:7,
        URL:require('../assets/kitchen-7.jpg'),
    },
    {
        id:8,
        URL:require('../assets/kitchen-8.jpg'),
    },
    {
        id:9,
        URL:require('../assets/kitchen-9.jpg'),
    },
    {
        id:10,
        URL:require('../assets/kitchen-10.jpg'),
    },
    {
        id:11,
        URL:require('../assets/kitchen-11.jpg'),
    },
    {
        id:12,
        URL:require('../assets/kitchen-12.jpg'),
    },


]


export default Data;