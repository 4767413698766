
const Item=[
    {
        id:1,
        URL:require('../assets/room-sleep10.jpg'),
    },
    {
        id:2,
        URL:require('../assets/room-sleep11.jpg'),
    },
    {
        id:3,
        URL:require('../assets/room-sleep12.jpg'),
    },
    {
        id:4,
        URL:require('../assets/room-sleep6.jpg'),
    },
    {
        id:5,
        URL:require('../assets/room-sleep7.jpg'),
    },
    {
        id:6,
        URL:require('../assets/room-sleep8.jpg'),
    },
    {
        id:7,
        URL:require('../assets/room-sleep9.jpg'),
    },
    {
        id:8,
        URL:require('../assets/room-sleep2.jpg'),
    },
    {
        id:9,
        URL:require('../assets/room-sleep3.jpg'),
    },
    {
        id:10,
        URL:require('../assets/room-sleep4.jpg'),
    },
    {
        id:11,
        URL:require('../assets/room-sleep5.jpg'),
    },

]


export default Item;