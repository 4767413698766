

const RoomWoods=[
    {
        id:1,
        URL:require('../assets/roomWood.jpg'),
    },
    {
        id:2,
        URL:require('../assets/roomWood2.jpg'),
    },
    {
        id:3,
        URL:require('../assets/roomWood3.jpg'),
    },
    {
        id:4,
        URL:require('../assets/roomWood4.jpg'),
    },
    {
        id:5,
        URL:require('../assets/roomWood5.jpg'),
    },
    {
        id:6,
        URL:require('../assets/roomWood6.jpg'),
    },


]


export default RoomWoods;