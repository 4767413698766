import React from 'react';
import Data from '../res/data';

const Kitchen = () => {
    document.title = "עץ התבור"

    function KitchenCardsDisplayed(props) {
        console.log(props)
        return ((
        <div className='room-3'>
        <div className='product-1' key={props.content.id}>
         <img className='img-kitchen' src={props.content.URL}></img>
        </div>
        </div>
        ));
    }
    return (
    <div>
    <div className='products-container'>
    <h1 className='roomSalon-logo'>מטבחים
    <a href='/'><button className='room-bath-a'>Home</button></a>
    </h1>
    {Data.map((id) => (
    <KitchenCardsDisplayed content={id} />
    ))}
    </div>
    </div>
    );
}

export default Kitchen;
