import React from 'react';
import Closet from '../res/closet';

const RoomCloth = () => {
    document.title = "עץ התבור"

    function RoomCardsDisplayed(props) {
        console.log(props)
        return ((
        <div className='room-5'>
       <div className='product-roomCloset1' key={props.content.id}>
       <img className='img-BadRoom' src={props.content.URL}></img>
       </div>
       </div>
       ));
       }
    return (
    <div>
    <div className='products-roomCloset'>
    <h1 className='room-logos'>חדר  ארונות
    <a href='/'><button className='room-bath-a'>Home</button></a>
    </h1>
    {Closet.map((id) => (
    <RoomCardsDisplayed content={id} />
    ))}
    </div>
                   
    </div>
    );
}

export default RoomCloth;
