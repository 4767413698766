import React, { useEffect } from 'react';
import { useState } from 'react';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);
    document.title = "עץ התבור"

    

    const toggleNavbar = () => {
      setIsOpen((prevState) => !prevState);
    };
    return (
      <body className='yourHome'>
    
      <div className='img-woods'>
    <div className='wood-img'>
    <img className='logo-img' src={require("../assets/logo-web.jpg")}></img>

    <h1 className='wood-tit'>עץ התבור</h1>
    <h1 className='wood-tits'>פתרונות ונגרות</h1>

    <a href='./project'>
    <button className='wood-button'>
    <h2>העבודה שלנו </h2>
    </button>
    </a>
    
    <div className='note'>
    <h2>אז מי אנחנו?
    עץ התבור הינה נגרייה מהממלצו
    ת ביותר בתחום הנגרות עם 
    ניסיון עשיר של 36 שנה עיצוב וביצוע פרוייקטים ותכנון מדויק</h2>  

    <li>
    <h3 className='tit-qual'>
    1-אחריות מלאה
    </h3>

    <h3 className='tit-qual'>
    2-תמחור הוגן
    </h3>

    <h3 className='tit-qual'>
    3-ניסיון עשיר
    </h3>

    <h3 className='tit-qual'>
    4-מקצועיות גבוהה ויחס אישי
    </h3>

    <h3 className='tit-qual'>
    5-פרזול הכי טוב ואמין
    </h3>
    </li>
    </div>   

    <div className='abouts'>
    <img className='wood-con' src={require("../assets/wood-con.jpg")}>
    </img>  
    <h1 className='carpentry-profile'>השאירו פרטים וקבלו את כל המידע
     בתחום לימודי נגרות</h1>
    <div className='style-input'>
    <input  placeholder='שם שלך'></input>
    <input placeholder='טלפון'></input>
    <input  placeholder='אימייל'></input>
    </div>
    <button className='button-input'><h2>קבל ברטים</h2></button>
    </div>

    <div>
    <img  className='kitchen-wood' src={require("../assets/kitchen-4.jpg")}>
    </img>
    </div>

    </div>

    <div className='header-about'>
    <img className='img-logo-abo' src={require("../assets/logo-web.jpg")}></img>

    <div className='email'>
    <h2>מוזמנים לבקר</h2>
    <div className='div-social'>
  <button className='instagram'>
  <a href='https://www.instagram.com/tabor_wood?igsh=azRraTJrcHc1Ym9r&utm_source=qr'>
  <img width="40" height="50" src="https://img.icons8.com/bubbles/50/instagram-new--v1.png" alt="instagram-new--v1"/>
  </a>
  </button>

  <button className='facebook'>
  <a href='https://www.facebook.com/Msmkt.alnor?mibextid=hu50Ix'>
  <img width="40" height="50" src="https://img.icons8.com/bubbles/50/facebook.png" alt="facebook"/>
  </a>
  </button>

  <button className='whatsapp'>
  <a href='https://wa.me/message/VP477Y4QJHITE1'>
  <img width="40" height="50" src="https://img.icons8.com/bubbles/50/whatsapp.png" alt="whatsapp"/>
  </a>
  </button>

  <button className='tiktok'>
  <a href='https://www.tiktok.com/@taborwood1?_t=8kB0XYKiZEn&_r=1'>
  <img width="40" height="50" src="https://img.icons8.com/bubbles/50/tiktok.png" alt="tiktok"/>
  </a>
  </button>
  </div>

    </div>

    </div>

    </div>
    </body>
     

    );
}

export default Home;
