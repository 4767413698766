const RoomSalons=[
    {
        id:1,
        URL:require('../assets/salonRoom.jpg'),
    },
    {
        id:2,
        URL:require('../assets/salonRoom2.jpg'),
    },
    {
        id:3,
        URL:require('../assets/salonRoom3.jpg'),
    },
    {
        id:4,
        URL:require('../assets/salonRoom4.jpg'),
    },
    {
        id:5,
        URL:require('../assets/salonRoom5.jpg'),
    },
    {
        id:6,
        URL:require('../assets/salonRoom6.jpg'),
    },
    {
        id:7,
        URL:require('../assets/salonRoom7.jpg'),
    },
    {
        id:8,
        URL:require('../assets/salonRoom8.jpg'),
    },
    {
        id:9,
        URL:require('../assets/saloonRoom9.jpg'),
    },
    {
        id:10,
        URL:require('../assets/salonRoom10.jpg'),
    },


]


export default RoomSalons;