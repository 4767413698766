import React from 'react';

const About = () => {
    return (
        <div>
        
        <div>
        <img className='img-about' src={require("../assets/kitchen-about.jpg")}>
        </img>
        </div>

        <div className='carpentry-title'>
        <h3>
        תתי התמחות של נגרים
        </h3>
        <h7>
         כמו כל מקצועי חופשית גם לנגרות יש תתי תחומים ויש נגרים שמעדפים 
         להתמחות בתחום ספציפי אפשר לחלק את התחומים לשלושה
        </h7>

        <h3>
        :נגרות מטבחים 
        </h3>
        <h7>
        למרות ההיצע העצום שיש היום של מטבחים ממוכנים 
        עדיין ברוב המקרים שיפוץ או בניית דירה  מצריכה  הגעה של  נגר כדי לתפור את המטבח 
        לפי המידות ותכנון שאנחנו רוצים
        נגר המטבחים טוב ידע לתכנן 
        .את המידות כמו שתצריך לפי הדרישות המקדימות
        בנוסף נגר מטבחים יעזור לכם לבחור את הפירזול הנכון והמתאים למטבח לוודא שהוא איכותי
        
        </h7>

        <h3>
        :נגרות בניין
        </h3>
        <h7>
        למרות שנגרות בניין נחשבת לנגרות 
        הבסיסית ביותר עדיין יש לנגר צורך להבין בתכנון והתקנה של דברים
        שוניפ בבנין 
        הוא צריך לדעת איך מתקינים ומחיצות 
        משקופים מדרכי על הגג ועוד
        לפני שאתם ניגשים ללמוד תחשבו מה אתם רוצים 
        לעצות בהמשך מה מעניין אתכם האם אתם 
        אוהבים לבנות מטבחים או אולי 
        דווקה נגרות קלאסית בלי מכונות חשמליות
        בלי קשר להתמחות ספציפית נגר טוב ידע לבצע 
        עם עבודות אחרות כמו התקנה פרגולות דקים מעץ
        שידות מיוחדות וכו
        
        
        </h7>

        
        <h3>
        :נגרות סלונים
        </h3>
        <h7>
        גם בסלונים יש היצע גדול מאוד,
         ולפיכך אפשר למצוא סלונים ברמות איכות שונות.
        ספה זה רהיט שלא מחליפים בתדירות גבוהה ולכן נרצה 
        שהאיכות של הסלון תהיה ברמה גבוהה מאוד.
        נגר שמתמחה בסלונים ידע לבחור את העץ הנכון לשלד של הסלון
        , הוא ידע מתי להשתמש בחיזוקי מתכת ומתי בברגי עץ ודבקים וכו.
        
        
        </h7>
        </div>

        <div>
        <img className='img-about' src={require("../assets/kitchen-about2.jpg")}>
        </img>
        </div>


  
        </div>
    );
}

export default About;
