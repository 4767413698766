import React from 'react';

const Contact = () => {
    return (

      <div className='img-contact'>
      <h1 className='title-contact'>ליצור קשר</h1>

      <div className='social-contact'>
      <button className='instagram-contact'>
  <a href='https://www.instagram.com/tabor_wood?igsh=azRraTJrcHc1Ym9r&utm_source=qr'>
  <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/instagram-new--v1.png" alt="instagram-new--v1"/>
  </a>
  </button>

  <button className='whatsapp-contact'>
  <a href='https://wa.me/message/VP477Y4QJHITE1'>
  <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/whatsapp--v1.png" alt="whatsapp--v1"/>
  </a>
  </button>

  <button className='tiktok-contact'>
  <a href='https://www.tiktok.com/@taborwood1?_t=8kB0XYKiZEn&_r=1'>
  <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/tiktok--v1.png" alt="tiktok--v1"/>
  </a>
  </button>

  <button className='facebook-contact'>
  <a href='https://www.facebook.com/Msmkt.alnor?mibextid=hu50Ix'>
  <img width="50" height="60" src="https://img.icons8.com/ios-glyphs/60/facebook-new.png" alt="facebook-new"/>
  </a>
  </button>
      </div>

      <div className='email-contact'>
        <h2>טלפון:0522122969</h2>
        <h2>0522931845:</h2>
        </div>

      <div>
      <h2 className='e-contact'>abobakera634@hotmail.com</h2>  
      <h2 className='e-contact'>תודה שבחרתם בעץ התבור</h2>  
      </div>


      </div>
  
    );
}

export default Contact;


