import React from 'react';
import DataRoom from '../res/dataRoom';
const RoomBath = () => {
    document.title = "עץ התבור"

    function RoomCardsDisplayed(props) {
        console.log(props)
        return ((
        <div className='room-5'>
       <div className='product-BadRoom' key={props.content.id}>
       <img className='img-BadRoom' src={props.content.URL}></img>
       </div>
       </div>
       ));
       }
    return (
    <div className='room-bath'>
    <div className='products-containerRoomBath'>
    <h1 className='room-logo'>ארונות אמבטיה
    <a href='/'><button className='room-bath-a'>Home</button></a>
    </h1>
    {DataRoom.map((id) => (
    <RoomCardsDisplayed content={id} />
    ))}
    </div>       
    </div>
    );
}

export default RoomBath;
