import React from 'react';
import {
    RouterProvider,
    createBrowserRouter,
  } from "react-router-dom";
import Contact from '../component/contact';
import Kitchen from '../component/kitchen';
import Home from '../component/home';
import RoomSleep from '../component/room-sleep';
import RoomBath from '../component/roomBath';
import RoomCloth from '../component/roomCloth';
import RoomWood from '../component/roomWood';
import RoomSalon from '../component/roomSalon';
import Project from '../component/project';
import About from '../component/about';
const Router = () => {
   const routes =  createBrowserRouter([
    {
      path: "/",
      element: <Home/> , 
      title:'abc'
    },
    {
        path: "/contact",
        element: <Contact/>
    },
    {
      path: "/about",
      element:<About/>
  },
    {
      path: "/project",
      element: <Project/>
    },
    {
        path: "/kitchen",
        element: <Kitchen/>
    },
    {
      path: "/roomSleep",
      element: <RoomSleep/>
    },
    {
      path: "/roomBath",
      element: <RoomBath/>
    },
    {
      path: "/roomCloth",
      element: <RoomCloth/>
    },
    {
      path: "/roomWood",
      element: <RoomWood/>
    },
    {
      path: "/roomSalon",
      element: <RoomSalon/>
    },


   
  ]);

  return (
    <RouterProvider router={routes} />
  )

}
export default Router;