
import './App.css';
import Home from './component/home';
import Router from './rout/router';

function App() {
  return (
   <div>
    <Router/>
   </div>
  );
}

export default App;
